import React from 'react'
import { Link, graphql } from 'gatsby'
import Masonry from 'react-masonry-component'
import Img from 'gatsby-image'
import Layout from "../components/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"

const PortraitPage = ({ data }) => (
  <Layout>
    <HelmetDatoCms seo={data.datoCmsPortraitsPage.seoMetaTags} />
    <Masonry className="showcase">
      {data.allDatoCmsPortrait.edges.map(({ node: portrait }) => (
        <div key={portrait.id} className="showcase__item">
          <figure className="card">
            <Link to={`/portraits/${portrait.slug}`} className="card__image">
              <Img fluid={portrait.coverImage.fluid} />
            </Link>
            {/* <figcaption className="card__caption">
              <h6 className="card__title">
                <Link to={`/portraits/${portrait.slug}`}>{portrait.title}</Link>
              </h6>
              <div className="card__description">
                <p>{portrait.excerpt}</p>
              </div>
            </figcaption> */}
          </figure>
        </div>
      ))}
    </Masonry>
  </Layout>
)

export default PortraitPage

export const query = graphql`
    query PageQuery {
      datoCmsPortraitsPage {
        seoMetaTags {
          tags
        }
      }
      allDatoCmsPortrait(sort: { fields: [position], order: ASC }) {
        edges {
          node {
            id
            title
            slug
            excerpt
            coverImage {
              fluid(maxWidth: 450, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
              }
            }
          }
        }
      }
    }
`
